import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "./RegisterScreen.scss";
import useDocumentTitle from "../../Title/useDocumentTitle";
import {GoogleLogin} from "@react-oauth/google";
import {createOrGetUser} from "../../../utils";

const RegisterScreen = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [pfp, setPfp] = useState('')

    useDocumentTitle(`Balkanflix - Register`);


    useEffect(() => {
        if (authToken) {
            localStorage.setItem("username", username);
            localStorage.setItem("pfp", pfp);
            localStorage.setItem("email", email);
            navigate("/");
        }
    }, [authToken, navigate, username, pfp, email]);

    const waitForDataAndRedirect = () => {
        const checkInterval = setInterval(() => {
            if (localStorage.getItem("authToken")) {
                clearInterval(checkInterval); // Stop the interval once data is found
                navigate("/"); // Redirect to home page
            }
        }, 100); // Check every 100ms
    };

    const registerHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords do not match");
        }

        try {
            const { data } = await axios.post("https://balkanflix-server.vercel.app/api/auth/register", { username, email, password }, config);
            localStorage.setItem("authToken", data.token);
            setAuthToken(data.token);
            setPfp(data.user.pfp)

            // navigate("/");
        } catch (e) {
            setError(e.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <div className="register-screen">
            <Link className="menu-return" to="/">{"<- GLAVNA STRANICA"}</Link>
            <div className="register-logo">
                <img src="/Balkanflix-removebg.png" alt="logo" height="70px" width="62px"/>
                <p>Balkanflix</p>
            </div>
            <form onSubmit={registerHandler} className="register-screen__form">
                <h3 className="register-screen__title">Registracija</h3>
                {error && <span className="error-message">{error}</span>}
                <div className="form-group">
                    <label htmlFor="name">Korisničko ime:</label>
                    <input type="text" required id="name" placeholder="Unesi korisničko ime" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" required id="email" placeholder="Unesi email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Lozinka:</label>
                    <input type="password" required id="password" placeholder="Unesi lozinku" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmpassword">Potvrdi lozinku:</label>
                    <input type="password" required id="confirmpassword" placeholder="Potvrdi lozinku" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>

                <button type="submit" className="register-btn">Registruj se</button>

                <span className="register-screen__subtext">
                    Već imate nalog? <Link className="subtext-link" to="/login">Prijavi se</Link>
                </span>

                <GoogleLogin
                    onSuccess={(r) => {
                        createOrGetUser(r).then(r => console.log(r))
                        waitForDataAndRedirect();
                    }}
                />
            </form>
        </div>
    );
};

export default RegisterScreen;
