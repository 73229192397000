import React, {useState} from 'react';
import "./notifications.css"
import axios from "axios";

const Notifications = () => {

    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationBody, setNotificationBody] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const handleClear = () => {
        setNotificationTitle("");
        setNotificationBody("");
        setFeedbackMessage("");
    };

    const handleSendNotification = async () => {
        setIsSending(true);
        setFeedbackMessage("");

        const payload = {
            title: notificationTitle,
            body: notificationBody,
        };

        try {
            const response = await axios.post(
                "https://balkanflix-server.vercel.app/api/push-tokens/send-to-all",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log(response.data);
            setFeedbackMessage("Obaveštenje uspešno poslato!");
            handleClear();
        } catch (error) {
            setFeedbackMessage(`Neuspešno slanje obaveštenja: ${error.message}`);
        } finally {
            setIsSending(false);
        }
    };

    const isFormValid = notificationTitle.trim() !== "" && notificationBody.trim() !== "";

    return (
        <div>
            <div className="notification-screen">
                <div>
                    <h2 className="notifications-header">Pošalji Obaveštenje!</h2>
                    <h5 className="notifications-subtitle">Kreiraj i pošalji prilagođena obaveštenja korisnicima.</h5>
                </div>

                <div>
                    <h4 className="notification-labels">Naslov Obaveštenja</h4>
                    <input
                        className="notification-inputs"
                        placeholder="Unesi naslov obaveštenja..."
                        type="text"
                        value={notificationTitle}
                        onChange={(e) => setNotificationTitle(e.target.value)}
                    />
                </div>

                <div>
                    <h4 className="notification-labels">Poruka Obaveštenja</h4>
                    <input
                        className="notification-inputs"
                        placeholder="Unesi poruku obaveštenja ovde..."
                        type="text"
                        value={notificationBody}
                        onChange={(e) => setNotificationBody(e.target.value)}
                    />
                </div>

                <div className="notification-buttons">
                    <button onClick={handleClear} className="notification-clear">Očisti</button>
                    <button
                        onClick={handleSendNotification}
                        className="notification-send"
                        disabled={isSending || !isFormValid}
                    >
                        {isSending ? "Slanje..." : "Pošalji Obaveštenje"}
                    </button>
                </div>

                {
                    feedbackMessage
                    &&
                    <p
                        className={`feedback-message ${
                            feedbackMessage.includes("successfully") ? "success" : "error"
                        }`}
                    >
                        {feedbackMessage}
                    </p>
                }

            </div>
        </div>
    );
};

export default Notifications;