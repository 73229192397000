import './dashboard.scss';
import TopBox from "../topBox/TopBox";
// import BarChartBox from "../barChartBox/BarChartBox";
// import PieChartBox from "../pieChartBox/PieChartBox";
import BigChartBox from "../bigChartBox/BigChartBox";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from "react";
// import BarChartBox2 from "../barChartBox/BarChartBox2";
import CombinedBarChartBox from "../barChartBox/BarChart";

const Dashboard = () => {
    const [users, setUsers] = useState(null);
    const [series, setSeries] = useState(null);
    const [viewsToday, setViewsToday] = useState(null);
    const [earningsByCountry, setEarningsByCountry] = useState([]);
    const [ecpmByCountry, setEcpmByCountry] = useState([]);
    const [totalViews, setTotalViews] = useState(0);



    const fetchDashboardData = async () => {
        try {

            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - 90);

            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);

            // Fetch total users and total series
            const usersResponse = await axios.get('https://balkanflix-server.vercel.app/api/auth/countAllUsers');
            setUsers(usersResponse.data.totalUsers);
            setSeries(usersResponse.data.totalSeries);

            // Fetch total views today
            const viewsTodayResponse = await axios.get('https://filemoonapi.com/api/account/stats?key=46529qdxsb1y65ki1juu9&last=30');
            setViewsToday(viewsTodayResponse.data.result[viewsTodayResponse.data.result.length - 1].views);
            setTotalViews(viewsTodayResponse.data.result.slice(30).reduce((acc, item) => acc + item.views, 0));

            // Fetch earnings by country
            const earningsResponse = await axios.get(`https://balkanflix-server.vercel.app/api/auth/publisher-stats/1059036?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=country&sort_by=-earnings`);
            setEarningsByCountry(earningsResponse.data);

            // Fetch users by country
            // const usersByCountryResponse = await axios.get(`https://balkanflix-server.vercel.app/api/auth/publisher-stats/1059036?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=country&sort_by=-unique_users`);
            // setUsersByCountry(usersByCountryResponse.data);

            // Fetch eCPM by country
            const ecpmByCountryResponse = await axios.get(`https://balkanflix-server.vercel.app/api/auth/publisher-stats/1059036?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=country&sort_by=-unique_users_ecpm`);
            setEcpmByCountry(ecpmByCountryResponse.data);

            // Assuming earningsResponse.data contains the fetched data
            // const totalRevenue = earningsResponse.data.map(item => parseFloat(item.earnings)).reduce((total, value) => total + value, 0);

// Format totalRevenue to 2 decimal places
//             setRevenue(totalRevenue.toFixed(2));

            // const exchangeRateResponse = await axios.get('https://api.exchangerate-api.com/v4/latest/USD'); // Replace with your chosen API URL
            // const usdToRsd = exchangeRateResponse.data.rates.RSD;

            // setExchangeRate(usdToRsd);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <div className="home">
            <div className="box box1">
                <TopBox />
            </div>
            <div className="box box2">
                <div className="chartBox">
                    <div className="boxInfo">
                        <div className="box_info_div">
                            <div className="title_dashboard">
                                <img src="/Dashboard/userIcon.svg" alt="dash"/>
                                <span>Ukupno korisnika</span>
                            </div>
                            <h1>{users !== null ? users : 'Loading...'}</h1>
                            <Link to="/dashboard/users">
                                Vidi više
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="box box3">
                <div className="chartBox">
                    <div className="boxInfo">
                        <div className="box_info_div">
                            <div className="title_dashboard">
                                <img src="/Dashboard/productIcon.svg" alt="dash"/>
                                <span>Ukupno serijala</span>
                            </div>
                            <h1>{series !== null ? series : 'Loading...'}</h1>
                            <Link to="/dashboard/series">
                                Vidi više
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="box box4">*/}
            {/*    <PieChartBox/>*/}
            {/*</div>*/}
            <div className="box box5">
                <div className="chartBox">
                    <div className="boxInfo">
                        <div className="box_info_div">
                        <div className="title_dashboard">
                                <img src="/Dashboard/conversionIcon.svg" alt="dash"/>
                                <span>Pregleda Danas/Mesec</span>
                            </div>
                            <h1>{viewsToday !== null && totalViews != null ? viewsToday + "/" + totalViews : 'Loading...'}</h1>
                            <Link to="https://filemoon.sx/reports">
                                Vidi više
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box box6">
                <div className="chartBox">
                    <div className="boxInfo">
                        <div className="box_info_div">
                            <div className="title_dashboard">
                                <img src="/Dashboard/revenueIcon.svg" alt="dash"/>
                                <span>Ukupno zarade</span>
                            </div>
                            {/*<h1>${revenue !== "" ? revenue + "/" + (revenue * exchangeRate).toFixed(0)+"RSD" : 'Loading...'}</h1>*/}
                            <h1>API...</h1>
                            <Link to="https://adcash.myadcash.com/pub_statistics/index?groupBy=site&sortBy=&dimensions=&startDate=2024-06-20&endDate=2024-06-26&filters=">
                                Vidi više
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="box box7">
                <BigChartBox/>
            </div>
            <div className="box box8">
                <CombinedBarChartBox
                    title1="Zarada po državi"
                    color1="#8884d8"
                    dataKey1="earnings"
                    chartData1={earningsByCountry}
                    title2="eCPM po državi"
                    color2="#82ca9d"
                    dataKey2="unique_users_ecpm"
                    chartData2={ecpmByCountry}
                />
                {/*<BarChartBox*/}
                {/*    title="Zarada po državi"*/}
                {/*    color="#8884d8"*/}
                {/*    dataKey="earnings"*/}
                {/*    chartData={earningsByCountry}*/}
                {/*/>*/}
            </div>
            {/*<div className="box box9">*/}
            {/*    <BarChartBox2*/}
            {/*        title="eCPM po državi"*/}
            {/*        color="#82ca9d"*/}
            {/*        dataKey="unique_users_ecpm"*/}
            {/*        chartData={ecpmByCountry}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};

export default Dashboard;
