import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import MovieCard from '../../../Series/Card/card';
import './movie-grid.scss';

const MovieGrid = props => {
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [filters, setFilters] = useState({
        genre: '',
        studio: '',
        status: '',
        type: ''
    });
    const [sortBy, setSortBy] = useState(''); // Initial sort not selected
    const { keyword } = useParams();

    useEffect(() => {
        const getListFromBackend = async () => {
            const sortParam = props.category; // 'popular', 'newest', or 'rated'
            try {
                let response;
                if (keyword) {
                    response = await axios.get(`https://balkanflix-server.vercel.app/api/content/search?query=${keyword}`);
                } else {
                    response = await axios.get(`https://balkanflix-server.vercel.app/api/content/seriesGrid?sort=${sortParam}`);
                }
                setItems(response.data.series);
                setAllItems(response.data.series); // Store the original dataset
            } catch (error) {
                console.error("Error fetching series:", error);
            }
        };
        getListFromBackend();
    }, [keyword, props.category]);

    useEffect(() => {
        let filteredItems = [...allItems]; // Start with the full dataset

        if (filters.genre) {
            filteredItems = filteredItems.filter(item =>
                item.genre && item.genre.includes(filters.genre)
            );
        }

        if (filters.studio) {
            filteredItems = filteredItems.filter(item =>
                item.studio && item.studio.trim().toLowerCase() === filters.studio.toLowerCase()
            );
        }

        if (filters.status) {
            filteredItems = filteredItems.filter(item =>
                item.status && item.status.toLowerCase() === filters.status.toLowerCase()
            );
        }

        if (filters.type) {
            filteredItems = filteredItems.filter(item =>
                item.type && item.type.toLowerCase() === filters.type.toLowerCase()
            );
        }

        // Apply sorting
        switch (sortBy) {
            case 'pregledi':
                filteredItems.sort((a, b) => b.totalViews - a.totalViews);
                break;
            case 'datum':
                filteredItems.sort((a, b) => new Date(b.date_sorted) - new Date(a.date_sorted));
                break;
            case 'ocena':
                filteredItems.sort((a, b) => b.MAL_ocena - a.MAL_ocena);
                break;
            default:
                break;
        }

        setItems(filteredItems);
    }, [allItems, filters, sortBy]);

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleFilterChange = (key, value) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    };

    return (
        <div className="search-grid">
            <aside className="filter-panel">
                <div className="filter-panel__header">
                    <h2>Opcije Filtriranja</h2>
                </div>
                <div className="filter-panel__content">
                    <label>
                        <span>Žanr:</span>
                        <select value={filters.genre} onChange={e => handleFilterChange('genre', e.target.value)}>
                            <option value="" className="nonopt">Izaberi žanr</option>
                            <option value="Akcija">Akcija</option>
                            <option value="Avantura">Avantura</option>
                            <option value="Fantazija">Fantazija</option>
                            <option value="Komedija">Komedija</option>
                            <option value="Drama">Drama</option>
                            <option value="Romansa">Romansa</option>
                            <option value="Sci-Fi">Sci-Fi</option>
                            <option value="Misterija">Misterija</option>
                            <option value="Sport">Sport</option>
                            <option value="Natprirodno">Natprirodno</option>
                            <option value="Ecchi">Ecchi</option>
                            <option value="Award Winning">Award Winning</option>
                            <option value="Shounen">Shounen</option>
                            <option value="Istorija">Istorija</option>
                            <option value="Škola">Škola</option>
                            <option value="Psihološki">Psihološki</option>
                            <option value="Triler">Triler</option>
                        </select>
                    </label>

                    <label>
                        <span>Studio:</span>
                        <select value={filters.studio} onChange={e => handleFilterChange('studio', e.target.value)}>
                            <option value="" className="nonopt">Izaberi produkciju</option>
                            <option value="Bones">Bones</option>
                            <option value="Pierrot">Pierrot</option>
                            <option value="A-1 Pictures">A1 Pictures</option>
                            <option value="Mappa">MAPPA</option>
                            <option value="LIDENFILMS">Liden Films</option>
                            <option value="Madhouse">Madhouse</option>
                            <option value="Gallop">Gallop</option>
                            <option value="Kyoto Animation">Kyoto Animation</option>
                            <option value="SILVER LINK.">SILVER LINK</option>
                            <option value="Studio Ghibli">Studio Ghibli</option>
                            <option value="Toei Animation">Toei Animation</option>
                            <option value="Sunrise">Sunrise</option>
                            <option value="Wit Studio">Wit Studio</option>
                            <option value="Ufotable">Ufotable</option>
                            <option value="Production I.G">Production I.G</option>
                            <option value="Trigger">Trigger</option>
                            <option value="Gainax">Gainax</option>
                            <option value="TMS Entertainment">TMS Entertainment</option>
                            <option value="Gonzo">Gonzo</option>
                            <option value="GEMBA">GEMBA</option>
                            <option value="ENGI">ENGI</option>
                            <option value="White Fox">White Fox</option>
                            <option value="J.C.Staff">J.C.Staff</option>
                            <option value="P.A. Works">P.A. Works</option>
                            <option value="E&H Production">E&H Production</option>
                            <option value="CloverWorks">CloverWorks</option>
                            <option value="Cloud Hearts">Cloud Hearts</option>
                            <option value="Satelight">Satelight</option>
                            <option value="Dugout">Dugout</option>
                            <option value="8bit">8bit</option>
                            <option value="Shuka">Shuka</option>
                            <option value="Lerche">Lerche</option>
                            <option value="Manglobe">Manglobe</option>
                        </select>
                    </label>

                    <label>
                        <span>Status:</span>
                        <select value={filters.status} onChange={e => handleFilterChange('status', e.target.value)}>
                            <option value="" className="nonopt">Izaberi status</option>
                            <option value="Uskoro">Uskoro</option>
                            <option value="Emituje se">Emituje se</option>
                            <option value="Završeno">Završeno</option>
                        </select>
                    </label>

                    {/*<label>*/}
                    {/*    <span>Tip:</span>*/}
                    {/*    <select value={filters.type} onChange={e => handleFilterChange('type', e.target.value)}>*/}
                    {/*        <option value="" className="nonopt">Izaberi tip</option>*/}
                    {/*        <option value="serija">Serija</option>*/}
                    {/*        <option value="film">Film</option>*/}
                    {/*        <option value="ova">OVA</option>*/}
                    {/*        <option value="ona">ONA</option>*/}
                    {/*        <option value="specijal">Specijal</option>*/}
                    {/*    </select>*/}
                    {/*</label>*/}

                    <label>
                        <span>Sortiraj po:</span>
                        <select value={sortBy} onChange={handleSortChange}>
                            <option value="" className="nonopt">Sortiraj po</option>
                            <option value="pregledi">Pregledima</option>
                            <option value="datum">Datumu</option>
                            <option value="ocena">Oceni</option>
                        </select>
                    </label>
                </div>
            </aside>

            <div className="movie-grid">
                {items.length > 0 ? (
                    items.map((item, index) => (
                        <MovieCard key={index} item={item}/>
                    ))
                ) : (
                    <p>Nema rezultata za prikaz.</p>
                )}
            </div>
        </div>
    );
};

export default MovieGrid;
