import React, { useEffect, useState } from "react";
 import axios from "axios";
 import { Upload, CheckCircle2, AlertCircle } from "lucide-react";
 import "./episodes.scss";
 
 const Episodes = () => {
     const [files, setFiles] = useState([]);
     const [isDragging, setIsDragging] = useState(false);
     const [seriesList, setSeriesList] = useState([]);
     const [selectedSeries, setSelectedSeries] = useState(null);
     const [episode, setEpisode] = useState("");
     const [disableDiscord, setDisableDiscord] = useState(false);
 
     useEffect(() => {
         // Fetch series list
         const fetchSeries = async () => {
             try {
                 const { data } = await axios.get(
                     "https://balkanflix-server.vercel.app/api/content/series"
                 );
                 setSeriesList(data.series);
             } catch (err) {
                 console.error("Error fetching series:", err.message);
             }
         };
         fetchSeries();
     }, []);
 
     const handleDragOver = (e) => {
         e.preventDefault();
         setIsDragging(true);
     };
 
     const handleDragLeave = (e) => {
         e.preventDefault();
         setIsDragging(false);
     };
 
     const handleDrop = (e) => {
         e.preventDefault();
         setIsDragging(false);
 
         const droppedFiles = Array.from(e.dataTransfer.files);
         const newFiles = droppedFiles.map((file) => ({
             id: Math.random().toString(36).substr(2, 9),
             file,
             name: file.name,
             progress: 0,
             status: "uploading",
         }));
 
         setFiles((prev) => [...prev, ...newFiles]);
         newFiles.forEach((file) => handleUpload(file));
     };
 
     const handleUpload = async (file) => {
         try {
             const { data } = await axios.get(
                 "https://balkanflix-server.vercel.app/api/episode/get-upload-server"
             );
 
             const { uploadServer, progressId } = data;
 
             const formData = new FormData();
             formData.append("key", "46529qdxsb1y65ki1juu9");
             formData.append("file", file.file);
 
             const uploadResponse = axios.post(
                 `${uploadServer}?X-Progress-ID=${progressId}`,
                 formData,
                 {
                     headers: { "Content-Type": "multipart/form-data" },
                 }
             );
 
             const pollProgress = async () => {
                 try {
                     const progressResponse = await axios.get(
                         `${uploadServer.replace("/upload/01", "/progress")}?X-Progress-ID=${progressId}`
                     );
 
                     const { state, received, size } = progressResponse.data;
 
                     if (state === "uploading") {
                         const progress = Math.round((received / size) * 100);
                         setFiles((prev) =>
                             prev.map((f) =>
                                 f.id === file.id ? { ...f, progress } : f
                             )
                         );
                     } else if (state === "done") {
                         setFiles((prev) =>
                             prev.map((f) =>
                                 f.id === file.id
                                     ? { ...f, status: "done", progress: 100 }
                                     : f
                             )
                         );
                         clearInterval(progressInterval);
 
                         const uploadResult = await uploadResponse;
                         const { filecode, status } = uploadResult.data.files[0];
                         if (status === "OK") {
                             handleRelease(file, filecode);
                         }
                     } else if (state === "failed") {
                         setFiles((prev) =>
                             prev.map((f) =>
                                 f.id === file.id ? { ...f, status: "failed" } : f
                             )
                         );
                         clearInterval(progressInterval);
 
                     }
                 } catch (err) {
                     console.error("Error polling progress:", err.message);
                     clearInterval(progressInterval);
                 }
             };
 
             const progressInterval = setInterval(pollProgress, 1000);
             pollProgress();
         } catch (error) {
             console.error("Error uploading file:", error);
             setFiles((prev) =>
                 prev.map((f) =>
                     f.id === file.id ? { ...f, status: "failed" } : f
                 )
             );
         }
     };
 
     const handleRelease = async (file, fileCode) => {
         if (!selectedSeries || !episode) {
             console.error("Series and episode must be selected before releasing.");
             return;
         }
 
         const { img, poster, title, title_params } = selectedSeries;
 
         try {
             const payload = {
                 series: title_params,
                 episode,
                 fileCode,
                 img,
                 poster,
                 title,
                 disableDiscord
             };
 
             const { data } = await axios.post(
                 "https://balkanflix-server.vercel.app/api/episode/release",
                 payload
             );
 
             console.log("Episode released successfully:", data);
         } catch (error) {
             console.error("Error releasing episode:", error.message);
         }
     };
 
     return (
         <div className="video-upload">
             <div className="upload-container">
 
                 <div
                     onDragOver={handleDragOver}
                     onDragLeave={handleDragLeave}
                     onDrop={handleDrop}
                     className={`drop-area ${isDragging ? "dragging" : ""}`}
                 >
                     <Upload className="upload-icon"/>
                     <h3 className="title">Drag & Drop</h3>
                     <p className="subtitle">
                         All video formats allowed. Max upload per file is 50GB.
                     </p>
                 </div>
 
                 <div className="selecton">
                     <select
                         value={selectedSeries?._id || ""}
                         onChange={(e) =>
                             setSelectedSeries(
                                 seriesList.find((series) => series._id === e.target.value)
                             )
                         }
                     >
                         <option value="" disabled>
                             Select Series
                         </option>
                         {seriesList.map((series) => (
                             <option key={series._id} value={series._id}>
                                 {series.title}
                             </option>
                         ))}
                     </select>
 
                     <input
                         type="number"
                         placeholder="Episode Number"
                         value={episode}
                         onChange={(e) => setEpisode(e.target.value)}
                         min="1"
                     />
                 </div>
 
                 <div className="discord-checkbox">
                     <label className="checkbox-container">
                         {/* Checkbox is checked when user DOES NOT want Discord notification */}
                         <input
                             type="checkbox"
                             checked={disableDiscord}
                             onChange={(e) => setDisableDiscord(e.target.checked)}
                         />
                         <span className="checkmark"></span>
                         Ako čekirate ovu opciju onda birate da se za ovu epizodu NE šalje poruka na Discord!
                     </label>
                 </div>
 
                 <div className="ep-stats">
                     <div className="file-list">
                         {files.map((file) => (
                             <div key={file.id} className="file-item">
                                 {file.status === "uploading" && (
                                     <>
                                         <div className="spinner"></div>
                                         <div className="progress-bar">
                                             <div
                                                 className="progress-indicator"
                                                 style={{width: `${file.progress}%`}}
                                             ></div>
                                         </div>
                                         <span className="progress-text">{file.progress}%</span>
                                     </>
                                 )}
                                 {file.status === "done" && (
                                     <>
                                         <span className="file-name done">{file.name}</span>
                                         <CheckCircle2 className="status-icon done"/>
                                         <span className="status-text done">Done</span>
                                     </>
                                 )}
                                 {file.status === "failed" && (
                                     <>
                                         <span className="file-name failed">{file.name}</span>
                                         <AlertCircle className="status-icon failed"/>
                                         <span className="status-text failed">Failed</span>
                                     </>
                                 )}
                             </div>
                             
                         ))}
                     </div>
                 </div>
             </div>
         </div>
     );
 };
 
 export default Episodes;