import React, {lazy, useEffect, useState, Suspense, useRef, useCallback} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import './episode.scss';
import axios from "axios";
import { IoEyeSharp } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import { IoCalendar } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackwardFast, faForwardFast, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import useDocumentTitle from "../../Title/useDocumentTitle";

// import reklama from "../../../assets/Reklama_sajt.gif"
// import MiniChat from "../../Chat/MiniChat";

const LazyMiniChat = lazy(() => import('../../Chat/MiniChat'));


const Episode = () => {
    const [seriesData, setSeriesData] = useState(null);
    // const [episodeData, setEpisodeData] = useState(null);
    const [episodeTitle, setEpisodeTitle] = useState("");
    const [episodeSrc, setEpisodeSrc] = useState("");
    const [episodeBf, setEpisodeBf] = useState("");
    const [episodes, setEpisodes] = useState([]);
    const [inputEpisode, setInputEpisode] = useState('');
    let [sortedSeries, setSortedSeries] = useState([]);
    const { ep, title } = useParams();
    const [sortOption, setSortOption] = useState('views');
    const [selectedServer, setSelectedServer] = useState('Filemoon');
//    const [startTime, setStartTime] = useState(null);s

    const [isChatVisible, setIsChatVisible] = useState(false); // State to track chat visibility
    const chatRef = useRef(null);

    const navigate = useNavigate();

    useDocumentTitle(`Balkanflix - ${title} Ep${ep} - Online Sa Prevodom`);

    const [startTime] = useState(new Date());

    const handleEpisodeEnd = useCallback(async () => {
        if (!startTime) {
            console.warn('Start time not defined, skipping episode end logic');
            return;
        }

        const endTime = new Date();
        try {
            await recordEpisodeWatchFull(ep, title, startTime, endTime);
            console.log('Episode watch recorded successfully');
        } catch (error) {
            console.error('Error recording episode watch:', error);
        }
    }, [startTime, title, ep]);

    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            await handleEpisodeEnd();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            handleEpisodeEnd();
        };
    }, [title, ep, handleEpisodeEnd]);

    const recordEpisodeWatchFull = async (episodeNumber, seriesTitle, startTime, endTime) => {
        console.log('Inside recordEpisodeWatch function');
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.log("No token available.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const user = localStorage.getItem("username");
            console.log('Sending request to server with data:', {
                user,
                seriesTitle,
                episodeNumber,
                startTime,
                endTime,
            });
            await axios.post('https://balkanflix-server.vercel.app/api/auth/watchedEpisodeFull', {
                user,
                seriesTitle,
                episodeNumber,
                startTime,
                endTime,
            }, config);
            console.log("Episode watch recorded for", episodeNumber);
        } catch (error) {
            console.error("Error recording episode watch:", error);
        }
    };

    useEffect(() => {
        const fetchSeriesData = async () => {
            try {
                const { data } = await axios.get(`https://balkanflix-server.vercel.app/api/content/series/${title}`);
                setSeriesData(data.series);
                const episode = data.series.episodes.find(episode => episode.ep.toString() === ep);
                if (episode) {
                    // setEpisodeData(episode);
                    await recordEpisodeWatch(episode.ep, title);
                } else {
                    console.log("Episode not found.");
                }
            } catch (error) {
                console.error('Error fetching series data:', error);
            }
        };

        fetchSeriesData();

    }, [title, ep]);

    useEffect(() => {
        const fetchEpisodeData = async () => {
            try {
                const { data } = await axios.get(`https://balkanflix-server.vercel.app/api/episode/episodesInfo/${title}`);
                const series = data.data;
                if (series) {
                    const episode = series.episodes.find(episode => episode.ep.toString() === ep);
                    if (episode) {
                        // setEpisodeData(episode);
                        setEpisodeTitle(series.title);
                        await updateEpisodeViews(series.title_params, episode.ep)

                        if (episode.src){
                            setEpisodeSrc(`https://filemoon.sx/e/${episode.src}`);
                            setSelectedServer('Filemoon')
                        }else{
                            setEpisodeSrc("");
                            setSelectedServer('Balkanflix');
                        }
                        if (episode.bf){
                            setEpisodeBf(`https://short.icu/${episode.bf}`);
                        
                        }else{
                            setEpisodeBf("")
                            setSelectedServer('Filemoon')
                        }
                        await recordEpisodeWatch(episode.ep, title);
                    } else {
                        console.log("Episode not found.");
                    }
                } else {
                    console.log("Series not found.");
                }
            } catch (error) {
                console.error('Error fetching episode data:', error);
            }
        };
        fetchEpisodeData();
    }, [title, ep]);

    useEffect(() => {
        const fetchSortedSeries = async () => {
            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/content/recommendedSortedSeries', {
                    params: { sortOption },
                });

                if (data.success && data.sortedSeries) {
                    setSortedSeries(data.sortedSeries);
                } else {
                    console.error('No series available');
                }
            } catch (error) {
                console.error('Error fetching sorted series:', error);
            }
        };

        fetchSortedSeries();
    }, [sortOption]);

    useEffect(() => {
        const fetchEpCount = async () => {
            try {
                const { data } = await axios.get(`https://balkanflix-server.vercel.app/api/episode/episodeCount/${title}`);
                setEpisodes(data.episode);
            }catch (e) {
                console.log(e)
            }
        }
        fetchEpCount();


    }, [title]);

    const recordEpisodeWatch = async (episodeNumber, seriesTitle) => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.log("No token available.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };

        try {
            const user = localStorage.getItem("username");
            await axios.post('https://balkanflix-server.vercel.app/api/auth/watchedEpisode', {
                user,
                seriesTitle,
                episodeNumber,
            }, config);
            console.log("Episode watch recorded for", episodeNumber);
        } catch (error) {
            console.error("Error recording episode watch:", error);
        }
    };

    const handleGoToEpisode = (e) => {
        if (e.key === 'Enter'){
            const episodeNumber = parseInt(inputEpisode);
            if (episodeNumber > 0 && episodeNumber <= episodes.length){
                navigate(`/watch/${title}/${episodeNumber}`);
            }else{
                alert('Invalid ep number');
            }
        }
    }

    const handleServerChange = (server) => {
        setSelectedServer(server);
    }

    const linkToTitle = `/${seriesData?.title.replace(/\s+/g, '')}`;
    const episodePlus = parseInt(ep) + 1;
    const episodeMinus = parseInt(ep) - 1;
    const fillerEpisodeStyle = {
        backgroundColor: 'purple',
    };

    const currentSrc = selectedServer === 'Balkanflix' ? episodeBf : episodeSrc;

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsChatVisible(true);
                }
            },
            { threshold: 0.1 }
        );

        // Store current ref value in a local variable
        const chatElement = chatRef.current;

        if (chatElement) {
            observer.observe(chatElement);
        }

        return () => {
            // Use the local variable in cleanup
            if (chatElement) {
                observer.unobserve(chatElement);
            }
        };
    }, []);

    const updateEpisodeViews = async (title, episodeNumber) => {
        try {
            await axios.patch(`https://balkanflix-server.vercel.app/api/episode/episodeViews/${title}/${episodeNumber}`);
        } catch (error) {
            console.error('Error updating episode views:', error);
        }
    };



    return (
        <div className="episode-screen">
            <div className="left-ep-scr">
                <div className="episode-wrapper">
                    <h2 className="episode-num">
                        <Link className="episode-title" to={linkToTitle}>{episodeTitle} - Epizoda {ep} Online sa prevodom</Link>
                    </h2>

                    <div className="player-holder">
                        <iframe
                            className="episode-player"
                            title={title}
                            src={currentSrc}
                            height="550"
                            width="1016"
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                </div>
                <div className="episode-count-holder">
                    <div className="episode-count">
                        <div className="players-control">
                            <div className="episode-skip">
                                {ep > 1 ?
                                    <div className="pervious-ep cont-txt">
                                        <Link to={`/watch/${title}/${episodeMinus}`} className="ep-active">
                                            <FontAwesomeIcon icon={faBackwardFast}/> Prošla epizoda
                                        </Link>
                                    </div>
                                    :
                                    <div className="pervious-ep cont-txt">
                                        <Link className="ep-inactive">
                                            <FontAwesomeIcon icon={faBackwardFast}/> Prošla epizoda
                                        </Link>
                                    </div>
                                }
                                {ep < episodes.length ?
                                    <div className="next-ep cont-txt">
                                        <Link to={`/watch/${title}/${episodePlus}`} className="ep-active">
                                            <FontAwesomeIcon icon={faForwardFast}/> Sledeća epizoda
                                        </Link>
                                    </div>
                                    :
                                    <div className="next-ep cont-txt">
                                        <Link className="ep-inactive">
                                            <FontAwesomeIcon icon={faForwardFast}/> Sledeća epizoda
                                        </Link>
                                    </div>
                                }
                            </div>
                            <div className="report-problem cont-txt">
                                <Link>
                                    <FontAwesomeIcon icon={faTriangleExclamation}/> Prijavi problem
                                </Link>
                            </div>
                        </div>

                        <input
                            className="ep-number-input"
                            type="number"
                            placeholder="Broj epizode"
                            value={inputEpisode}
                            onChange={(e) => setInputEpisode(e.target.value)}
                            onKeyDown={handleGoToEpisode}
                        />

                        <div className="episode-list-ep">
                            {episodes.map((episode, index) => (
                                <Link key={index} to={`/watch/${title}/${episode.ep}`} className="ep-number-btn"
                                      style={episode.isFiller ? fillerEpisodeStyle : {}}>
                                    {episode.ep}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                {episodeSrc !== "" && episodeBf !== "" && (
                    <div className="server-list">
                        <h3>Izaberite server:</h3>
                        <button onClick={() => handleServerChange('Balkanflix')} className={selectedServer === 'Balkanflix' ? 'active-server' : 'episode-server'}>Balkanflix</button>
                        <button onClick={() => handleServerChange('Filemoon')} className={selectedServer === 'Filemoon' ? 'active-server' : 'episode-server'}>Filemoon</button>
                    </div>
                )}

                <div className="series-description">
                    <img src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesData?.img}`} className="series-img" alt="serijal"/>
                    <div className="series-data">
                        <Link className="series-description-name" to={linkToTitle}> {seriesData?.title} </Link>
                        <h4 className="series-description-genres">{seriesData?.genre.map((item, key) => (
                            <Link key={key} className="series-description-genres-item">{item}</Link>
                        ))}</h4>

                        <div className="series-description-data">
                            <div className="series-description-data-row">
                                <h5>Epizoda: {seriesData?.ep}</h5>
                                <h5>Datum: {seriesData?.date}</h5>
                                <h5>Status: {seriesData?.status}</h5>
                            </div>
                            <div className="series-description-data-row">
                                <h5>Studio: {seriesData?.studio}</h5>
                                <h5>Ocena: {seriesData?.MAL_ocena}</h5>
                                <h5>Pregledi: {seriesData?.totalViews}</h5>
                            </div>
                        </div>

                        <h5 className="series-desc-text">{seriesData?.description}</h5>
                    </div>
                </div>
            </div>

            <div className="right-ep-screen">

                {/*<div className="naruto-shp">*/}
                {/*    <Link to="https://narutoshop.rs/?ref=balkanflix">*/}
                {/*        <img src={reklama} alt="Naruto Shop"/>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <div ref={chatRef} className="mini-chat">
                    <Suspense fallback={<div>Loading chat...</div>}>
                        {isChatVisible && <LazyMiniChat />}
                    </Suspense>
                </div>
                <div className="mw-sort">
                    <div className="mst-viewed">
                        <h3>Predlažemo</h3>
                        <div className="type-choose">
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="sort-fy"
                                    value="views"
                                    checked={sortOption === 'views'}
                                    onChange={() => setSortOption('views')}
                                />
                                <span className="date-name">Pregledi</span>
                            </label>
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="sort-fy"
                                    value="rating"
                                    onChange={() => setSortOption('rating')}
                                />
                                <span className="date-name">Ocena</span>
                            </label>
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="sort-fy"
                                    value="newest"
                                    onChange={() => setSortOption('newest')}
                                />
                                <span className="date-name">Najnovije</span>
                            </label>
                        </div>
                    </div>
                    <div className="views-sort">
                        <ul>
                            {sortedSeries.map((item) => (
                                <li key={item.title}>
                                    <img
                                        src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${item.img}`}
                                        alt="sortirano"
                                    />
                                    <div className="title-views">
                                        <Link to={`https://balkanflix.com/${item.title_params}`} target="_parent"
                                              className="title-sort">{item.title}</Link>
                                        <div className="sort-stats">
                                            <p><IoEyeSharp/> {item.totalViews}</p>
                                            <p><FaStar/> {item.MAL_ocena}</p>
                                            <p><IoCalendar/> {item.date_sorted}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="tags">
                    <h2>Tagovi:</h2>
                    <p>{episodeTitle} Online Sa Prevodom</p>
                    <p>{episodeTitle} Besplatno Gledanje</p>
                    <p>Besplatno Online {episodeTitle}</p>
                    <p>{episodeTitle} na Srpskom</p>
                    <p>{episodeTitle} na Hrvatskom</p>
                </div>
            </div>
        </div>
    )
}

export default Episode;
