import React, { useEffect, useState } from 'react';
import './details.scss';
import '../Episode/episode.scss';
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../Title/useDocumentTitle';
import { BsBookmarkPlus } from "react-icons/bs";
import { BsFillBookmarkPlusFill } from "react-icons/bs";
import { ReactComponent as MySVG } from '../../Buttons/wave-svg.svg';

const Details = () => {
    const { title } = useParams();
    const [seriesDataData, setSeriesDataData] = useState(null);
    const [episodes, setEpisodes] = useState([]);
    const uskoro = "Uskoro"
    const [visibleEpisodes, setVisibleEpisodes] = useState(0);
    const [isSaved, setIsSaved] = useState(false);
    const [isSticky, setIsSticky] = useState(false); // Novi state za sticky
    const trimmedTitle = title.replace(/\s/g, '');
    const totalEpisodes = episodes.length;
    const navigate = useNavigate();
    useDocumentTitle(`Balkanflix - ${title} Online Sa Prevodom`);

    const showNextEpisodes = () => {
        const nextVisibleEpisodes = visibleEpisodes + 40;
        setVisibleEpisodes(nextVisibleEpisodes > totalEpisodes ? totalEpisodes : nextVisibleEpisodes);
    };

    const showPreviousEpisodes = () => {
        const previousVisibleEpisodes = visibleEpisodes - 40;
        setVisibleEpisodes(previousVisibleEpisodes < 0 ? 0 : previousVisibleEpisodes);
    };

    useEffect(() => {
        const fetchEpCount = async () => {
            try {
                const { data } = await axios.get(`https://balkanflix-server.vercel.app/api/episode/episodeCount/${trimmedTitle}`);
                setEpisodes(data.episode);
            } catch (e) {
                console.log(e);
            }
        };
        fetchEpCount();

        const updateTotalViews = async () => {
            try {
                await axios.put(`https://balkanflix-server.vercel.app/api/content/update-total-views/${trimmedTitle}`);
            } catch (e) {
                console.log(e);
            }
        };
        updateTotalViews();
    }, [trimmedTitle]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`https://balkanflix-server.vercel.app/api/content/seriesDetail/${title}`);
                setSeriesDataData(data.series[0]);
                fetchUserData(data.series[0]);
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, [title]);

    const fetchUserData = async (seriesData) => {
        if (!seriesData) {
            console.error("No series provided to fetchUserData");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };
        try {
            const { data } = await axios.get('https://balkanflix-server.vercel.app/api/private', config);
            const isCurrentlySaved = data.favorites.includes(seriesData.title);
            setIsSaved(isCurrentlySaved);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const previousSeason = seriesDataData?.previous && seriesDataData.previous.length > 0 ? seriesDataData.previous[0] : null;
    const nextSeason = seriesDataData?.next && seriesDataData.next.length > 0 ? seriesDataData.next[0] : null;

    const toggleSaved = async () => {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
            navigate(`/login`);
            return;
        }

        const apiUrl = isSaved ? 'https://balkanflix-server.vercel.app/api/auth/removeFavorite' : 'https://balkanflix-server.vercel.app/api/auth/addFavorite';
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };

        try {
            await axios.post(apiUrl, { title: seriesDataData?.title }, config);
            setIsSaved(!isSaved);
        } catch (error) {
            console.error("Error updating favorites", error);
            alert("Failed to update favorites");
        }
    };

    const fillerEpisodeStyle = {
        backgroundColor: 'purple', // Styling for filler episodes
    };

    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('footer');
            if (footer) {
                const footerRect = footer.getBoundingClientRect();
                const svgRect = document.querySelector('.svg-container').getBoundingClientRect();
                setIsSticky(footerRect.top <= svgRect.height);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll(); // Pozovi funkciju inicijalno

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="banner"
                 style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesDataData?.poster})` }}>
            </div>
            <div className="mb-3 movie-content container">
                <div className="movie-content__poster">
                    <div className="movie-content__poster__img"
                         style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesDataData?.img})` }}>
                    </div>
                </div>
                <div className="movie-content__info">
                    <div className="title-info">
                        <div className="title-info-header">
                            <div className="tal">
                                <h1 className="title">
                                    {seriesDataData?.title}
                                </h1>
                                <div className="anime-logo">
                                    {seriesDataData?.lg && (
                                    <img
                                        src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesDataData?.lg}.webp`}
                                        alt="Logo"/>
                                    )}
                                </div>
                            </div>
                            <div className="genres">
                                {seriesDataData?.genre?.map((item) => (
                                    <span className="genres__item" key={item}>{item}</span>
                                ))}
                            </div>
                        </div>
                    </div>

                    <p className="overview">{seriesDataData?.description}</p>

                    <div className="episode-list-container">
                        <div className="ep-list-up">
                        <h1>Gledaj</h1>
                            <button onClick={toggleSaved}>{isSaved ? 'Sačuvano' : 'Sačuvaj'} {isSaved ?
                                <BsFillBookmarkPlusFill/> : <BsBookmarkPlus/>}</button>
                        </div>
                        <div className="episode-list">
                            {episodes.length > 0 ? (
                                <>
                                    {episodes.slice(visibleEpisodes, visibleEpisodes + 40).map((episode, index) => (
                                        <Link key={index} to={`/watch/${trimmedTitle}/${episode.ep}`} target="_top"
                                              style={episode.isFiller ? fillerEpisodeStyle : {}}
                                              className="ep-number-btn">
                                            {episode.ep}
                                        </Link>
                                    ))}
                                    {totalEpisodes > 40 && (
                                        <div className="episode-arrows">
                                            <button onClick={showPreviousEpisodes}
                                                    disabled={visibleEpisodes === 0}>{'<'}
                                            </button>
                                            <button onClick={showNextEpisodes}
                                                    disabled={visibleEpisodes + 40 >= totalEpisodes}>{'>'}
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <h1>{uskoro}</h1>
                            )}
                        </div>
                    </div>
                    {(previousSeason || nextSeason) && (
                        <div className="season-container">
                            {previousSeason &&
                                <div>
                                    <div className="season">
                                        <img
                                            src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesDataData.previous[2]}`}
                                            alt={previousSeason}/>
                                        <div className="season-title">
                                            <h2>Prethodna sezona:</h2>
                                            <Link to={`/${seriesDataData.previous[1]}`}
                                                  className="previous-season__link">{previousSeason}</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                            {nextSeason &&
                                <div>
                                    <div className="season">
                                        <img
                                            src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${seriesDataData.next[2]}`}
                                            alt={nextSeason}/>
                                        <div className="season-title">
                                            <h2>Sledeća sezona:</h2>
                                            <Link to={`/${seriesDataData.next[1]}`}
                                                  className="next-season__link">{nextSeason}</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>

            <div className={`svg-container ${isSticky ? 'sticky' : ''}`}>
                <MySVG/>
            </div>
        </>
    );
}

export default Details;