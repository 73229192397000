import "./topBox.scss"
import {useEffect, useState} from "react";
import axios from "axios";

const TopBox = () => {

    const [topUsers, setTopUsers] = useState([]);

    useEffect(() => {
        const fetchTopUsers = async () => {
            try {
                const response = await axios.get('https://balkanflix-server.vercel.app/api/auth/getTopUsersByEpisodesWatched');
                setTopUsers(response.data.topUsers);
            } catch (error) {
                console.error("Error fetching top users:", error);
            }
        };

        fetchTopUsers();
    }, []);

    return (
        <div className="topBox">
            <h1>Top Gledaoci</h1>
            <div className="list">
                {topUsers.map(user => (
                    <div className="listItem" key={user._id}>
                        <div className="topbox_user">
                            <img src={user.pfp} alt="pfp" />
                            <div className="userTexts">
                                <span className="username_dashboard">{user._id}</span>
                            </div>
                        </div>
                        <span className="amount">{user.totalEpisodesWatched}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopBox