import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import "./changeInfoForm.scss";
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
import { FaPen} from "react-icons/fa6";

export const ChangeInfoForm = () => {

    const [selectedPfpCategory, setSelectedPfpCategory] = useState(null);
    const [pfp, setPfp] = useState()
    const [newPfp, setNewPfp] = useState()
    const [newUsername, setNewUsername] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [bannerIndex, setBannerIndex] = useState(0);
    const [pfpModalOpen, setPfpModalOpen] = useState(false);

    const imageLists = {
        banner: [
            "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/Banner1.webp",
            "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/Banner2.webp",
            "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/Banner3.webp",
            "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/Banner4.webp",
            "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/Banner5.webp",
        ],
        pfp: {
            Naruto: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp2.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp3.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp4.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp5.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp6.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp7.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp8.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp9.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp10.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp11.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp12.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp13.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp14.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp15.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp16.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp17.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp18.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp19.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp20.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp21.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp22.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp23.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp24.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp25.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp26.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/narutopfp27.jpg",
            ],
            Jojo: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jojopfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jojopfp2.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jojopfp3.webp",
            ],
            OnePiece: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/onepiecepfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/onepiecepfp2.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/onepiecepfp3.webp",
            ],
            Bleach: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/bleachpfp1.webp",
            ],
            DragonBall: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp1.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp2.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp3.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp4.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp5.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp6.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp7.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp8.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp9.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp10.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp11.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp12.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp13.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp14.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp15.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp16.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp17.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/dbpfp18.jpg",
            ],
            Berserk: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/berserkpfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/berserkpfp2.webp",
            ],
            JJK: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp1.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp2.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp3.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp4.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp5.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/jjpfp6.jpg",
            ],
            AoT: [
            ],
            BlueLock: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp1.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp2.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp3.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp4.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp5.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp6.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp7.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp8.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp9.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp10.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp11.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp12.jpg",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blpfp13.jpg",
            ],
            HxH: [
            ],
            DemonSlayer: [
            ],
            Ostalo: [
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/yugiohpfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/hogurashipfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/kakeguruipfp1.webp",
                "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/blackcloverpfp1.webp",
            ],
        },
    };

    const togglePfpModal = () => {
        setPfpModalOpen(!pfpModalOpen);
    };

    const previewPfp = (profilePic) => {
        setPfp(profilePic)
        setNewPfp(profilePic);
        togglePfpModal()
    }

    const selectPfpCategory = (category) => {
        setSelectedPfpCategory(category);
    };

    const renderPfpOptions = () => {
        if (!selectedPfpCategory) {
            return null;
        }

        return imageLists.pfp[selectedPfpCategory].map((imageUrl, index) => (
            <img key={index} src={imageUrl} alt={`PFP ${index + 1}`} onClick={() => previewPfp(imageUrl)}/>
        ));
    };

    const nextBanner = () => {
        setBannerIndex((prevIndex) =>
            prevIndex === imageLists.banner.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevBanner = () => {
        setBannerIndex((prevIndex) =>
            prevIndex === 0 ? imageLists.banner.length - 1 : prevIndex - 1
        );
    };

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };

    const fetchUser = useCallback(async () => {
        // 2. Move config inside the function
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };

        try {
            const { data } = await axios.get(
                "https://balkanflix-server.vercel.app/api/private",
                config
            );
            setPfp(data.pfp);
        } catch (error) {
            console.error("Server error", error);
        }
    }, []);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const changeInfo = async () => {
        const updateData = {};
        if (newUsername) updateData.newUsername = newUsername;
        if (newEmail) updateData.newEmail = newEmail;
        updateData.newBanner = imageLists.banner[bannerIndex];
        if (newPfp) updateData.newPfp = newPfp;

        try {
            const response = await axios.put(
                "https://balkanflix-server.vercel.app/api/auth/updateProfile",
                updateData,
                config
            );
            if (newUsername){
                localStorage.setItem("username", newUsername)
            }
            if (newPfp) {
                setPfp(newPfp);
                localStorage.setItem("pfp", newPfp);
            }
            setNewUsername("");
            setNewEmail("");
            setNewPfp("");
            window.location.reload();

            return response;
        } catch (error) {
            console.error("Error updating profile:", error);
            alert(
                "Failed to update profile. Error: " +
                (error.response?.data?.error || "Server error")
            );
        }
    };

    return (
        <>
            {pfpModalOpen && (
                <div className="pfp-modal">
                    <div className="pfp-modal-content">
                        <h2>Izaberite novu profilnu sliku</h2>
                        <div className="pfp-categories">
                            {Object.keys(imageLists.pfp).map((category) => (
                                <button key={category} onClick={() => selectPfpCategory(category)}>
                                    {category}
                                </button>
                            ))}
                        </div>
                        <div className="pfp-options">
                            {renderPfpOptions()}
                        </div>
                        <button onClick={togglePfpModal}>Odustani</button>
                    </div>
                </div>
            )}
            <div className="modal-change-info">
                <div className="modal-content-info">
                    <div className="change-info-form">
                        <h1>Uredi nalog</h1>
                        <h5>Prilagodite informacije</h5>
                        <div className="change-banner">
                            <IoIosArrowDropleftCircle
                                className="banner-arrows"
                                onClick={prevBanner}
                            />
                            <img src={imageLists.banner[bannerIndex]} alt="Banner" />
                            <IoIosArrowDroprightCircle
                                className="banner-arrows"
                                onClick={nextBanner}
                            />
                        </div>
                        <div className="change-pfp" onClick={togglePfpModal}>
                            <img src={pfp} alt="PFP"/>
                            <div className="pen-edit">
                                <FaPen className="pen"/>
                            </div>
                        </div>
                        <div className="content-info-form__inputs">
                            <div className="content-info-form__input">
                                <label htmlFor="username">Korisničko ime</label>
                                <input
                                    placeholder="Unesi novo korisničko ime"
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                />
                            </div>
                            <div className="content-info-form__input">
                                <label htmlFor="email">Email</label>
                                <input
                                    placeholder="Unesi novi email"
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <button className="content-info-form__btn" onClick={changeInfo}>
                            Izmeni
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangeInfoForm;