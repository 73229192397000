import "./tsChat.scss"
import {useEffect, useRef, useState} from "react";
import client, {COLLECTION_ID, DATABASE_ID, databases} from "../../components/tsChat/appwriteConfig/appwriteConfig";
import {ID, Query} from "appwrite";
import {IoSend} from "react-icons/io5";

const TranslatorsChat = () => {

    console.log("CHAT ADMIN")

    const [msg, setMsg] = useState([]);
    const [msgBody, setMsgBody] = useState('');
    const msgEndRef = useRef(null);
    const [shouldReconnect, setShouldReconnect] = useState(true);

    const username = localStorage.getItem('username');

    const scrollToBottom = () => {
        msgEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const [cooldown, setCooldown] = useState(false);

    useEffect(() => {
        scrollToBottom();
    }, [msg]);


    useEffect(() => {
        getMessages();

        let unsubscribe;

        const connectRealtime = () => {
            unsubscribe = client.subscribe(`databases.${DATABASE_ID}.collections.${COLLECTION_ID}.documents`, response => {
                if (response.events.includes("databases.*.collections.*.documents.*.create")) {
                    setMsg(prevState => [...prevState, response.payload]);
                }
            }, error => {
                console.error("Realtime subscription error:", error);
                if (shouldReconnect) {
                    setTimeout(connectRealtime, 5000); // try to reconnect every 5 seconds
                }
            });
        };

        connectRealtime();

        return () => {
            setShouldReconnect(false); // When component unmounts, prevent reconnection
            if (unsubscribe) unsubscribe();
        };
    }, [shouldReconnect]);

    const getMessages = async ( ) => {
        const res = await databases.listDocuments(
            DATABASE_ID,
            COLLECTION_ID,
            [
                Query.orderAsc('$createdAt'),
                Query.limit(10000)
            ]
        );
        console.log('RES: ', res);
        setMsg(res.documents);
    }

    const formatTime = dateStr => {
        const date = new Date(dateStr);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cooldown) return; // Spreči slanje ako je cooldown aktivan

        setCooldown(true); // Aktiviraj cooldown odmah

        const username = localStorage.getItem('username');
        const pfp = localStorage.getItem('pfp');

        let payload = {
            body: msgBody,
            username: username,
            pfp: pfp
        };

        try {
            let res = await databases.createDocument(
                DATABASE_ID,
                COLLECTION_ID,
                ID.unique(),
                payload
            );

            console.log("Sent: ", res);

            setMsgBody('');
        } catch (error) {
            console.error("Error sending message:", error);
        }

        setTimeout(() => {
            setCooldown(false);
        }, 1000);
    };

    return (
        <div className="ts-topBox">
            <div className="list">
                <div className="chat">
                    <h1>Chat💬</h1>
                    <div className="messages">
                        {msg.map(message => (
                            <div className={message.username === username ? "message-my" : "message"} key={message.$id}>
                                <div className="name">
                                    <img src={message.pfp} alt="PFP"/>
                                    <h3>{message.username}</h3>
                                    <p>{formatTime(message.$createdAt)}</p>
                                </div>
                                <div className="message-holder">
                                    <p>{message.body}</p>
                                </div>
                            </div>
                        ))}
                        <div ref={msgEndRef}></div>
                    </div>
                    <form onSubmit={handleSubmit} className="message-input">
                        <input
                            type="text"
                            placeholder="Unesi tekst..."
                            required
                            maxLength="200"
                            onChange={(e) => {
                                setMsgBody(e.target.value)
                            }}
                            value={msgBody}
                        />
                        <button type="submit" disabled={cooldown}>
                            <IoSend/>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TranslatorsChat