import { useCallback, useEffect, useState } from 'react';
import './UserPage.scss';
import axios from "axios";
import { FaPen } from "react-icons/fa6";
import {Link} from "react-router-dom";
import Header from "../Mainscreen/components/header/Header";
import Footer from "../Mainscreen/components/footer/Footer";
import { ChangeInfoForm } from "../Buttons/changeInfoForm/changeInfoForm";

const UserPage = () => {

    const [username, setUsername]  = useState("");
    const [banner, setBanner] = useState("");
    const [pfp, setPfp] = useState("");
    const [email, setEmail]  = useState("");
    // const [createdAt, setCreatedAt]  = useState("");
    // const [favorites, setFavorites]  = useState([]);
    const [cards, setCards]  = useState([]);
    const [episodeCount, setEpisodeCount] = useState(0);
    const [gw, setGw] = useState(0);

    const [open, setOpen] = useState(false);

    const toggleModal = () => {
        setOpen(!open);
    };


    const fetchUser = useCallback(async () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };

        try {
            const { data } = await axios.get('https://balkanflix-server.vercel.app/api/private', config);
            setUsername(data.username);
            setEmail(data.email);
            setBanner(data.banner);
            setPfp(data.pfp);
            setEpisodeCount(data.full_ep.length);
            setGw(data.gw.length);
        } catch (error) {
            console.error("Server error", error);
        }
    }, []);


    const fetchAnimeCards = useCallback(async () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };

        try {
            const { data } = await axios.get('https://balkanflix-server.vercel.app/api/auth/getProfileCards', config);
            setCards(data.animeCards);
        } catch (error) {
            console.error("Error fetching anime cards:", error);
        }
    }, []);

    useEffect(() => {
        fetchUser();
        fetchAnimeCards();
    }, [fetchUser, fetchAnimeCards]);

    return (
        <>
            <Header />
            <div className="user-page">
                <div className="up-left">
                        <img src={banner} className="up-banner" alt="Banner"/>
                    <div className="up-pen" onClick={toggleModal}>
                        <FaPen/>
                    </div>
                    <img className="up-profpic" src={pfp} alt="profile pic"/>
                    <div className="up-section">
                        <h2>Korisničke informacije:</h2>
                        <div className="info-box">
                            <div className="info-item">
                                <p className="info-label">Korisničko ime:</p>
                                <div className="info-value">{username}</div>
                            </div>
                            <div className="info-item">
                                <p className="info-label">Email:</p>
                                <div className="info-value">{email}</div>
                            </div>
                            <div className="info-item">
                                <p className="info-label">Odgledano epizoda:</p>
                                <div className="info-value">{episodeCount}</div>
                            </div>
                            <div className="info-item">
                                <p className="info-label">Giveaway epizode:</p>
                                <div className="info-value">{gw}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="up-right">
                    <div className="section">
                        <h2>Sačuvani Anime:</h2>
                        <div className="anime-list">
                            {
                                cards.map((anime, index) => (
                                    <Link key={index} className="save-up" target="_top" to={'/' + anime.title_params}>
                                        <div className="up-movie-card" style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${anime.img})` }}>                                        </div>
                                        <h3>{anime.title}</h3>
                                    </Link>
                                ))
                            }
                            {
                                cards.length < 1 ? <h2>Nema sacuvanih serijala</h2> : null
                            }

                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
            {open && <ChangeInfoForm setOpen={setOpen}/>}
        </>

    );
}

export default UserPage;