import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './calendar.scss';
import { Link } from 'react-router-dom';

const Calendar = () => {
    const [schedule, setSchedule] = useState([]);
    const [loading, setLoading] = useState(true);

    const name = localStorage.getItem("username");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/schedule/animeCalendar');
                const convertedData = data.map(item => {
                    return {
                        ...item,
                        originalTime: item.time,
                        time: convertToBelgradeTime(item.time),
                        date: formatDate(item.time),
                        countdown: calculateCountdown(item.time),
                        imageSrc: getImageUrl(item.img),
                    };
                });
                setSchedule(convertedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [name]); // Trigger fetch whenever name changes

    useEffect(() => {
        const timer = setInterval(() => {
            setSchedule(prevSchedule => prevSchedule.map(item => ({
                ...item,
                countdown: calculateCountdown(item.originalTime),
            })));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const convertToBelgradeTime = (isoString) => {
        const date = new Date(isoString);
        const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Belgrade' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Belgrade' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const calculateCountdown = (isoString) => {
        const eventDate = new Date(isoString);
        const now = new Date();
        const difference = eventDate - now;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
            return 'USKORO';
        }
    };

    const getImageUrl = (imageName) => {
        return `https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${imageName}`;
    };

    console.log(schedule);

    return (
        <div className="schedule-container">
            <div className="schedule-header">
                <h1>Procenjeno vreme izlaska</h1>
            </div>
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                <div className="schedule-list">
                    {schedule.map((item, index) => (
                        <Link to={`/${item.title_params}`} key={index} className={`schedule-item ${name === item.pv ? "bg-change" : ""}`}>
                            <div className="anime-image-schedule">
                                <img src={item.imageSrc} alt={item.title} className="fixed-size-image" />
                            </div>
                            <div className="details">
                                <div className="schedule-title">{item.title}</div>
                                <div className="episode">Epizoda {item.ep}</div>
                                <div className="date-time">
                                    <div className="date">{item.date}</div>
                                    <div className="time">{item.time}</div>
                                </div>
                                <div className={`translator ${name === item.pv ? "translator-glow": ""}`}>{item.pv}</div>
                                <div className="countdown">{item.countdown}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Calendar;