import { useState } from "react";
import axios from "axios";
import "./form.scss";

const Forms = () => {
    const [animeId, setAnimeId] = useState("");
    const [animeIdNoTMDB, setAnimeIdNoTMDB] = useState("");
    const [animeIdMovie, setAnimeIdMovie] = useState("");
    const [tmdbId, setTmdbId] = useState("");
    const [movieTmdbId, setMovieTmdbId] = useState("");
    const [successCreatingSeries, setSuccessCreatingSeries] = useState({ message: "", type: "", visible: false });

    const showSuccess = (message, type) => {
        setSuccessCreatingSeries({ message, type, visible: true });
        setTimeout(() => setSuccessCreatingSeries({ message: "", type: "", visible: false }), 3000);
    };


    const fetchAnimeData = async (id, tmdb) => {
        try {
            const response = await axios.post('https://balkanflix-server.vercel.app/api/content/create', { seriesCode: id, tmdbSeriesId: tmdb });
            showSuccess("Anime napravljen uspešno!", "success");
            return response;
        } catch (err) {
            showSuccess("Anime nije napravljen!", "error");
        }
    };

    const fetchMovie = async (id, tmdb) => {
        try {
            const response = await axios.post('https://balkanflix-server.vercel.app/api/content/createMovies', { seriesCode: id, tmdbSeriesId: tmdb});
            showSuccess("Anime napravljen uspešno!", "success");
            return response;

        } catch (err) {
            showSuccess("Anime nije napravljen!", "error");
        }
    };

    const fetchAnimeDataNoTMDB = async (id) => {
        try {
            const response = await axios.post('https://balkanflix-server.vercel.app/api/content/createNoTMDB', { seriesCode: id});
            showSuccess("Anime napravljen uspešno!", "success");
            return response;

        } catch (err) {
            showSuccess("Anime nije napravljen!", "error");
        }
    };

    // Form submit handler
    const handleSubmit = (e) => {
        e.preventDefault();
        if (animeId && tmdbId) {
            fetchAnimeData(animeId, tmdbId); // Fetch anime data based on entered ID
        }
    };

    const handleMovie = (e) => {
        e.preventDefault();
        if (animeIdMovie && movieTmdbId) {
            fetchMovie(animeIdMovie, movieTmdbId); // Fetch anime data based on entered ID
        }
    };

    const handleNoTMDB = (e) => {
        e.preventDefault();
        if (animeIdNoTMDB) {
            fetchAnimeDataNoTMDB(animeIdNoTMDB); // Fetch anime data based on entered ID
        }
    };

    return (
        <>
            <div className="form-overlay">
                <div className="form-content">
                    {/* ----------------------------------NOTIFIKACIJA---------------------------------- */}
                    <div className="add-notification-form">
                        <h1>Notifikacija</h1>
                        <h5>Unesi informacije za notifikaciju</h5>
                        <div className="add-notification-form__inputs">
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="serial">Serijal</label>
                                <input
                                    placeholder="Unesi serijal"
                                    type="text"
                                />
                            </div>

                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="email">Epizoda</label>
                                <input
                                    placeholder="Unesi epizodu"
                                    type="number"
                                />
                            </div>
                        </div>

                        <button className="add-notification-form__btn">
                            Pošalji Notifikaciju
                        </button>
                    </div>
                    {/* ----------------------------------JIKAN API---------------------------------- */}
                    <form onSubmit={handleSubmit} className="add-notification-form">
                        <h1>Napravi Serijal</h1>
                        <h5>Enter the anime ID to get details</h5>
                        <div className="add-notification-form__inputs">
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="animeId">MAL</label>
                                <input
                                    id="animeId"
                                    placeholder="Unesi ID"
                                    type="text"
                                    value={animeId}
                                    onChange={(e) => setAnimeId(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="tmdbId">TMDB</label>
                                <input
                                    id="tmdbId"
                                    placeholder="Unesi ID"
                                    type="text"
                                    value={tmdbId}
                                    onChange={(e) => setTmdbId(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <button type="submit" className="add-notification-form__btn">
                            Kreiraj Serijal
                        </button>

                        {successCreatingSeries.visible && (
                            <div className={`notification-success ${successCreatingSeries.type}`}>
                                {successCreatingSeries.message}
                            </div>
                        )}
                    </form>
                </div>

                <div className="form-content">

                    {/* ----------------------------------NO TMDB---------------------------------- */}
                    <form onSubmit={handleNoTMDB} className="add-notification-form">
                        <h1>Napravi Serijal(Bez Postera)</h1>
                        <h5>Enter the anime ID to get details</h5>
                        <div className="add-notification-form__inputs">
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="animeIdTmdb">MAL</label>
                                <input
                                    id="animeIdTmdb"
                                    placeholder="Unesi ID"
                                    type="text"
                                    value={animeIdNoTMDB}
                                    onChange={(e) => setAnimeIdNoTMDB(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <button type="submit" className="add-notification-form__btn">
                            Kreiraj Serijal
                        </button>

                        {successCreatingSeries.visible && (
                            <div className={`notification-success ${successCreatingSeries.type}`}>
                                {successCreatingSeries.message}
                            </div>
                        )}
                    </form>
                    {/* ----------------------------------MOVIES API---------------------------------- */}
                    <form onSubmit={handleMovie} className="add-notification-form">
                        <h1>Napravi Film</h1>
                        <h5>Enter the anime ID to get details</h5>
                        <div className="add-notification-form__inputs">
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="movieId">MAL</label>
                                <input
                                    id="movieId"
                                    placeholder="Unesi ID"
                                    type="text"
                                    value={animeIdMovie}
                                    onChange={(e) => setAnimeIdMovie(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="add-notification-form__input">
                                <label className="anime-id" htmlFor="movieTmdbId">TMDB</label>
                                <input
                                    id="movieTmdbId"
                                    placeholder="Unesi ID"
                                    type="text"
                                    value={movieTmdbId}
                                    onChange={(e) => setMovieTmdbId(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <button type="submit" className="add-notification-form__btn">
                            Kreiraj Serijal
                        </button>

                        {successCreatingSeries.visible && (
                            <div className={`notification-success ${successCreatingSeries.type}`}>
                                {successCreatingSeries.message}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Forms;