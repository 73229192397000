// import React, {useState} from 'react';
import "./seriesNoEpisodes.css"
import {Tv, Users } from "lucide-react"

const SeriesNoEpisodes = () => {

    // const [totalSeries, setTotalSeries] = useState(0);
    // const [totalUsers, setTotalUsers] = useState(0);

    return (
        <div className="series-no-episodes-bg">
            <div className="upper-info">
                <div className="upper-info-left">
                    <div>
                        <h3>Total Anime Series</h3>
                        {/*<h2>{totalSeries}</h2>*/}
                    </div>
                    <div className="icon-tv-dash">
                        <Tv />
                    </div>
                </div>
                <div className="upper-info-right">
                    <div>
                        <h3>Total Users</h3>
                        {/*<h2>{totalUsers}</h2>*/}
                    </div>
                    <div className="icon-users-dash">
                        <Users />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeriesNoEpisodes;