import {useEffect, useState} from "react";
import axios from "axios";
import "./deleteAcc.scss";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../Title/useDocumentTitle";

const DeleteAcc = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");

    const history = useNavigate();

    useDocumentTitle(`Balkanflix - Delete Profile`);

    useEffect(() => {
        if (!localStorage.getItem("authToken")){
            history("/login");
        }
    }, [history]);

    const deleteAccountHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.delete(
                "https://balkanflix-server.vercel.app/api/auth/deleteProfile",
                { data: { email } },
                config
            );

            setSuccess(data.success);
            localStorage.clear();
            setTimeout(() => {
                history("/login");
            }, 1000);
        } catch (error) {
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <div className={"deleteacc-screen"}>
            <div className="delete-logo">
                <img src="/Balkanflix-removebg.png" alt="logo" height="70px" width="62px"/>
                <p>Balkanflix</p>
            </div>
            <form onSubmit={deleteAccountHandler} className="deleteacc-screen__form">
                <h3 className="deleteacc-screen__title">Obriši nalog</h3>
                {error && <span className="error-message">{error}</span>}
                {success && <span className="success-message">{success}</span>}
                <div className="form-group">
                    <p className="deleteacc-screen__subtext">
                        Zdravo {username}! Kliknite dugme ispod kako biste obrisali nalog.
                    </p>
                </div>
                <button type="submit" className="delete__btn">
                    Obriši nalog
                </button>
            </form>
        </div>
    );
};

export default DeleteAcc;
