import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './recentEpisodeForm.scss';

const RecentEpisodeForm = ({ closeModal }) => {
    const [recentEpisodes, setRecentEpisodes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            };

            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/private/episodeList', config);
                if (data.episodeWatchHistory && data.episodeWatchHistory.length) {
                    const lastFiveEpisodes = data.episodeWatchHistory.slice(-5).reverse();
                    setRecentEpisodes(lastFiveEpisodes.map(episode => ({
                        ...episode,
                        seriesTitle: addSpacesToTitle(episode.seriesTitle),
                        img: episode.img
                    })));
                }
            } catch (error) {
                console.error('Error fetching recent episodes:', error);
            }
        };

        fetchData();


    }, []);

    const addSpacesToTitle = (title) => {
        return title.replace(/([A-Z])/g, ' $1').trim();
    };

    const handleCloseModal = () => {
        closeModal();
    };

    return (
        <div className="recent-episodes-modal">
            <div className="recent-episodes-modal-content">
                <h2>Poslednje gledano:</h2>
                <ul>
                    {recentEpisodes.map((episode, index) => (
                        <li key={index}>
                            <Link to={`/watch/${episode.seriesTitle.replace(/\s/g, '')}/${episode.episodeNumber}`}
                                  onClick={handleCloseModal}>
                                <span>{index + 1}</span>
                                <img src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${episode.img}`} alt="img"/>
                                {episode.seriesTitle} - ep {episode.episodeNumber}
                            </Link>
                        </li>
                    ))}
                </ul>
                <button onClick={handleCloseModal}>Ugasi</button> {/* Close button */}
            </div>
        </div>
    );
};

export default RecentEpisodeForm;