import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {useEffect, useCallback} from "react";

import DeleteAcc from "./components/deleteAcc/DeleteAcc";

//Routing
import PrivateRoute from './components/routing/PrivateRoute';
import PrivateDashboard from './components/routing/PrivateDashboard';
import PrivateTranslator from "./components/routing/PrivateDev";

//Screens
import LoginScreen from './components/Authentication/Login/LoginScreen';
import RegisterScreen from './components/Authentication/Register/RegisterScreen';
import ForgotPasswordScreen from './components/Authentication/ForgotPass/ForgotPasswordScreen';
import ResetPasswordScreen from './components/Authentication/ResetPass/ResetPasswordScreen';
import UserPage from "./components/UserPage/userPage";
import Chat from './components/Chat/Chat';

//Dashboard
import Dashboard from './components/Dashboard/components/home/dashboard'
import Products from "./components/Dashboard/Products/productsdb";
import UsersPage from "./components/Dashboard/components/users/userspagedb";
import Layout from "./components/Dashboard/layout"
import Episodes from "./components/Dashboard/components/episodes/episodes"
import Forms from "./components/Dashboard/components/form/form";

//MainScreen
import MainHome from "./components/Mainscreen/pages/Home";
import MainLayout from "./components/Mainscreen/mainLayout";
import Catalog from "./components/Mainscreen/pages/Catalog";
import AboutUs from "./components/AboutUs/aboutUs";

//Episode
import Episode from "./components/Series/Episode/episode"
import Details from "./components/Series/Details/details"

//OAuth
import {GoogleOAuthProvider} from "@react-oauth/google";
import Terms from "./components/Terms/terms";
import Error404 from "./components/error404/error404";

//Translator Dash
import TranslatorProducts from "./components/TranslatorDash/Products/productsdb";
import TranslatorsPage from "./components/TranslatorDash/components/users/translatorsPage";
import TranslatorLayout from "./components/TranslatorDash/layout"
import TranslatorForms from "./components/TranslatorDash/components/form/form";
import TranslatorProfil from "./components/TranslatorDash/components/home/dashboard";
import Calendar from "./components/TranslatorDash/components/calendar/calendar";
import TranslatorEpisodes from "./components/TranslatorDash/components/episodes/episodes";

import Notes from "./components/TranslatorDash/components/notes/notes";
import SubtitleUploader from "./components/TranslatorDash/components/subtitleUploader/subtitleUploader";
import Notifications from "./components/TranslatorDash/components/notifications/notifications";
import SeriesNoEpisodes from "./components/TranslatorDash/components/emptySeries/seriesNoEpisodes";




const App = () => {

    const checkPermission = () => {
        if (!('serviceWorker' in navigator)) {
            throw new Error("No support for service worker!")
        }

        if (!('Notification' in window)) {
            throw new Error("No support for notification API");
        }

        if (!('PushManager' in window)) {
            throw new Error("No support for Push API")
        }
    }
    const registerSW = async () => {
        try {
            const registration = await navigator.serviceWorker.register('/sw.js');
            console.log('Service Worker registered with scope:', registration.scope);
        } catch (error) {
            console.error('Service Worker registration failed:', error);
        }
    }
    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            throw new Error("Notification permission not granted")
        }

    }
    const main = useCallback(async () => {
        checkPermission();
        await requestNotificationPermission();
        await registerSW();
    }, []);
    useEffect(() => {
        main().then(r => console.log);
    }, [main]);

    return (
        <GoogleOAuthProvider clientId="1011305876980-8hg4rjmefaq4r24ssrt1qplvqda1gbc3.apps.googleusercontent.com">
            <Router>
                <div className="app">
                    <Routes>
                        <Route path="/dashboard" element={<PrivateDashboard/>}>
                            <Route path="/dashboard" element={<Layout />}>
                                <Route index element={<Dashboard />} />
                                <Route path="users" element={<UsersPage />} />
                                <Route path="series" element={<Products />} />
                                <Route path="forms" element={<Forms />} />
                                <Route path="posts" element={<Episodes />} />
                            </Route>
                        </Route>

                        <Route path="/dev" element={<PrivateTranslator/>}>
                            <Route path="/dev" element={<TranslatorLayout />}>
                                <Route index element={<TranslatorProfil />} />
                                <Route path="translators" element={<TranslatorsPage />} />
                                <Route path="series" element={<TranslatorProducts />} />
                                <Route path="forms" element={<TranslatorForms />} />
                                <Route path="posts" element={<TranslatorEpisodes />} />
                                <Route path="notes" element={<Notes />} />
                                <Route path="subtitles" element={<SubtitleUploader />} />
                                <Route path="calendar" element={< Calendar />} />
                                <Route path="episodes" element={< TranslatorEpisodes />} />
                                <Route path="notifications" element={< Notifications />} />
                                <Route path="no-episodes" element={< SeriesNoEpisodes />} />
                            </Route>
                        </Route>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<MainHome />} />
                            {/*<Route path=":category/:id" element={<Detail />} />*/}
                            <Route path="/search/:keyword" element={<Catalog />} />
                            <Route path="/category/:category" element={<Catalog />} />
                            <Route path="/:title" element={<Details />} />
                            <Route path="/watch/:title/:ep" element={<Episode/>} />
                            <Route path="/o-nama" element={<AboutUs/>} />
                            <Route path="/uslovi-koriscenja" element={<Terms/>} />
                            <Route path="/deleteAcc" element={<DeleteAcc/>} />
                        </Route>
                        <Route path="/profil" element={<UserPage/>} />
                        <Route path="/404" element={<Error404/>} />
                        <Route path="/login" element={<LoginScreen />} />
                        <Route path="/register" element={<RegisterScreen />} />
                        <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
                        <Route path="/passwordreset/:resetToken" element={< ResetPasswordScreen />} />
                        <Route path="/chat" element={<PrivateRoute />}>
                            <Route path="/chat" element={< Chat />} />
                        </Route>
                    </Routes>
                </div>
        </Router>
    </GoogleOAuthProvider>
  );
}
export default App;

